import React from "react";
import { useTranslation } from "react-i18next";
import { FormSelectResponseVO } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { ResponseChangedCallback } from "components/PatientForms/hooks/usePatientResponses";

type Props = {
  id: string;
  questionUuid: string;
  response: FormSelectResponseVO | undefined;
  onChangeResponse: ResponseChangedCallback;
  isMulti?: boolean;
  placeholder?: string;
  edit?: boolean;
  autoFocus?: boolean;
};
export const PatientFormOtherOption = React.forwardRef<HTMLInputElement, Props>(
  (
    { id, response, onChangeResponse, questionUuid, edit, isMulti = false, placeholder, autoFocus = false },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <FormFieldInput
        className="text-greyDark max-w-md"
        ref={ref}
        edit={edit}
        id={`other-${id}`}
        value={response?.other}
        placeholder={placeholder ?? t("Other")}
        onFocus={() => {
          onChangeResponse(questionUuid, {
            type: "SELECT",
            other: response?.other,
            responses: isMulti ? (response?.responses ?? {}) : {},
          });
        }}
        autoFocus={autoFocus}
        onChange={(e) => {
          onChangeResponse(questionUuid, {
            type: "SELECT",
            other: e.target.value,
            responses: response?.responses ?? {},
          });
        }}
      />
    );
  }
);
