import { PracticeInfoVO, ProcedurePriorityOptionVO } from "@libs/api/generated-api";
import { EditablePatientInfoFields, ExistingPatientInfo } from "components/PatientForms/FormElements/types";
import { PatientFormConsent } from "components/PatientForms/FormPDFElements/PatientFormConsent";
import { PatientFormInputElement } from "components/PatientForms/FormPDFElements/PatientFormInputElement";
import { PatientFormProcedureTableElement } from "components/PatientForms/FormPDFElements/PatientFormProcedureTableElement";
import { PatientFormSection } from "components/PatientForms/FormPDFElements/PatientFormSection";
import { PatientFormTextBlock } from "components/PatientForms/FormPDFElements/PatientFormTextBlock";
import { PatientInfoFormElement } from "components/PatientForms/FormPDFElements/PatientInfoFormElement";
import { PatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { FormElement, isInputElement } from "components/PatientForms/utils";

type Props = {
  responsesById: PatientResponses;
  contentItem: FormElement;
  edit: boolean;
  patient: ExistingPatientInfo;
  patientInfoSubmission?: EditablePatientInfoFields;
  practice: PracticeInfoVO;
  priorityOptions: ProcedurePriorityOptionVO[];
};

export const PatientFormContentItem: React.FC<Props> = ({
  contentItem,
  responsesById,
  edit,
  patient,
  patientInfoSubmission,
  practice,
  priorityOptions,
}) => {
  const sharedProps = {
    responsesById,
    edit,
  };
  const key = contentItem.uuid;

  if (isInputElement(contentItem)) {
    return <PatientFormInputElement key={key} element={contentItem} {...sharedProps} />;
  }

  switch (contentItem.type) {
    case "CONSENT": {
      return <PatientFormConsent key={key} element={contentItem} {...sharedProps} />;
    }
    case "SECTION": {
      return (
        <PatientFormSection
          key={key}
          priorityOptions={priorityOptions}
          element={contentItem}
          patient={patient}
          practice={practice}
          {...sharedProps}
        />
      );
    }
    case "TEXT_BLOCK": {
      return <PatientFormTextBlock key={key} element={contentItem} {...sharedProps} />;
    }
    case "PROCEDURE_TABLE": {
      return (
        <PatientFormProcedureTableElement priorityOptions={priorityOptions} key={key} element={contentItem} />
      );
    }
    case "PATIENT_INFO": {
      return (
        <PatientInfoFormElement
          key={key}
          element={contentItem}
          patient={patient}
          edit={edit}
          patientInfoSubmission={patientInfoSubmission}
          practice={practice}
        />
      );
    }
    // these are not for patient forms
    case "EDITABLE_TEXT_BLOCK":
    case "APPOINTMENT_DETAILS": {
      return null;
    }
  }
};
