import { Trans, useTranslation } from "react-i18next";
import { FormEvent, Ref, useCallback } from "react";
import OTPInput from "otp-input-react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { Form } from "@libs/components/UI/Form";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";

type Props = {
  practice: PracticeInfoVO;
  onSubmit: Func;
  error?: string;
  pin: string;
  formRef: Ref<HTMLFormElement>;
  onChange: (pin: string) => void;
};

const OTP_LENGTH = 4;

export const KioskPinForm: React.FC<Props> = ({ formRef, practice, onSubmit, pin, error, onChange }) => {
  const { t } = useTranslation();

  const handleOneTimeCodeSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit();
    },
    [onSubmit]
  );

  return (
    <div className="flex flex-col h-full items-center">
      <AnonUserMessagePage
        title={
          <Trans
            i18nKey="app.page.messages.welcomeTo"
            values={{ practiceName: practice.name }}
            tOptions={{ interpolation: { escapeValue: true } }}
            shouldUnescape
          />
        }
        subTitle={t("kiosk.pin")}
        logoUrl={practice.logo?.url}
      >
        <Form
          ref={formRef}
          className="mt-8 flex flex-col items-center justify-center gap-8"
          onSubmit={handleOneTimeCodeSubmit}
        >
          {error && <FormFieldError>{error}</FormFieldError>}
          <OTPInput
            autoFocus
            inputClassName="border border-greyLight w-10 mr-2.5 box-border rounded-[5px]"
            // Styles set in `inputStyles` take precedence over
            // `inputClassName` and we need to override the built-in
            // defaults which forces us to style here and in the CSS class.
            inputStyles={{ height: "40px" }}
            onChange={onChange}
            OTPLength={OTP_LENGTH}
            otpType="string"
            placeholder="----"
            value={pin}
          />

          <Button theme="link" type="submit" disabled={pin.length < OTP_LENGTH}>
            {t("Next")}
          </Button>
        </Form>
      </AnonUserMessagePage>
    </div>
  );
};
