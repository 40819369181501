import React, { PropsWithChildren } from "react";

import { Trans, useTranslation } from "react-i18next";
import { View } from "@react-pdf/renderer";
import { FormPatientInfoElementVO, PracticeInfoVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { formatPhoneNumber } from "@libs/utils/phone";
import { EditablePatientInfoFields, ExistingPatientInfo } from "components/PatientForms/FormElements/types";
import { PatientInfoFormSection } from "components/PatientForms/FormPDFElements/PatientInfoFormSection";
import { PatientInfoFormTextItem } from "components/PatientForms/FormPDFElements/PatientInfoFormTextItem";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { pdfStyles } from "components/PatientForms/FormPDFElements/styles";
import { Spacer } from "components/PatientForms/FormPDFElements/Spacer";
import { PDFRadioList } from "components/PatientForms/FormPDFElements/PDFRadioList";
import { usePronounOptions } from "hooks/usePronounOptions";
import { useGenderOptions } from "hooks/useGenderOptions";

type Props = {
  element: FormPatientInfoElementVO;
  edit: boolean;
  patient: ExistingPatientInfo;
  patientInfoSubmission?: EditablePatientInfoFields;
  practice: PracticeInfoVO;
};

const RowWithColumns: React.FC<PropsWithChildren> = ({ children }) => {
  return <View style={pdfStyles.row}>{children}</View>;
};

const formatNullablePhoneNumber = (phoneNumber?: string) => {
  return phoneNumber ? (formatPhoneNumber(phoneNumber) ?? "") : "";
};

const Column: React.FC<PropsWithChildren> = ({ children }) => (
  <View style={pdfStyles.flexFull}>{children}</View>
);

export const PatientInfoFormElement: React.FC<Props> = ({
  patient,
  patientInfoSubmission,
  edit,
  element,
  practice,
}) => {
  const { t } = useTranslation();
  const sharedProps = { edit };
  const address = patientInfoSubmission?.address;
  const pronounOptions = usePronounOptions();
  const genderOptions = useGenderOptions();

  return (
    <>
      <PatientInfoFormSection title={t("Personal")}>
        <RowWithColumns>
          <PatientInfoFormTextItem
            {...sharedProps}
            label={t("First Name")}
            value={patient.firstName}
            required
          />
          {element.optionalFields.includes("MIDDLE_NAME") ? (
            <PatientInfoFormTextItem
              {...sharedProps}
              label={t("Middle Name")}
              value={patientInfoSubmission?.middleName}
            />
          ) : null}

          <PatientInfoFormTextItem
            {...sharedProps}
            label={t("Last Name")}
            value={patient.lastName}
            required
          />
        </RowWithColumns>
        <RowWithColumns>
          <PatientInfoFormTextItem
            {...sharedProps}
            label={t("Preferred Name")}
            value={patientInfoSubmission?.preferredName}
          />
          <Column />
          <Column />
        </RowWithColumns>
        <RowWithColumns>
          <Column>
            <PDFRadioList
              label={t("Gender")}
              options={genderOptions}
              selectedValue={patientInfoSubmission?.gender}
            />
          </Column>
          <Column>
            {element.optionalFields.includes("PREFERRED_PRONOUNS") ? (
              <>
                <PDFRadioList
                  label={t("Preferred Pronouns")}
                  options={pronounOptions}
                  selectedValue={patientInfoSubmission?.preferredPronouns}
                />
                <Spacer size="md" />
              </>
            ) : null}
          </Column>
          <Column />
        </RowWithColumns>
        <RowWithColumns>
          <PatientInfoFormTextItem
            {...sharedProps}
            label={t("Date of Birth")}
            required
            value={formatISODate(patient.dob)}
          />
          {patient.hasSsn ? (
            <Column />
          ) : (
            <PatientInfoFormTextItem {...sharedProps} label={t("SSN")} value={patientInfoSubmission?.ssn} />
          )}
          {patient.hasReferredBy ? (
            <Column />
          ) : (
            <PatientInfoFormTextItem
              {...sharedProps}
              label={t("How did you hear about us?")}
              value={patientInfoSubmission?.referredBy}
            />
          )}
        </RowWithColumns>
      </PatientInfoFormSection>
      {patient.hasContact ? null : (
        <PatientInfoFormSection title={t("Contact")}>
          <RowWithColumns>
            <PatientInfoFormTextItem {...sharedProps} label={t("Email")} value={patient.email} />
            <PatientInfoFormTextItem
              {...sharedProps}
              label={t("Cell Phone")}
              value={formatNullablePhoneNumber(patientInfoSubmission?.mobilePhone)}
            />
            <PatientInfoFormTextItem
              {...sharedProps}
              label={t("Home Phone")}
              value={formatNullablePhoneNumber(patientInfoSubmission?.homePhone)}
            />
          </RowWithColumns>
          <RowWithColumns>
            <PatientInfoFormTextItem
              {...sharedProps}
              label={t("Work Phone")}
              value={formatNullablePhoneNumber(patientInfoSubmission?.workPhone)}
            />
            <Column />
            <Column />
          </RowWithColumns>
          <Spacer size="xl" />

          {
            // wrap=false so address fields don't break across pages
          }
          <View wrap={false}>
            <RowWithColumns>
              <PatientInfoFormTextItem {...sharedProps} label={t("Address")} value={address?.address1} />
              <PatientInfoFormTextItem
                {...sharedProps}
                label={t("app.page.labels.apartmentUnitSuitFloor")}
                value={address?.address2}
              />
            </RowWithColumns>
            <RowWithColumns>
              <PatientInfoFormTextItem {...sharedProps} label={t("City")} value={address?.city} />
              <PatientInfoFormTextItem {...sharedProps} label={t("State")} value={address?.state} />
              <PatientInfoFormTextItem
                {...sharedProps}
                label={t("Zipcode")}
                style={{ width: 90 }}
                value={address?.zip}
              />
            </RowWithColumns>
          </View>
          <Text bold={false}>
            <Trans
              i18nKey="forms.patientInfo.disclaimer"
              values={{ practiceName: practice.name }}
              tOptions={{ interpolation: { escapeValue: true } }}
              shouldUnescape
            />
          </Text>
        </PatientInfoFormSection>
      )}
    </>
  );
};
