import { Outlet } from "react-router-dom";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { getProfileImageQuery } from "api/patient/queries";
import { AccountsMenu } from "components/Main/Header/AccountsMenu";
import { Header } from "components/Main/Header/Header";

export const AuthLayout: React.FC = () => {
  const { id: userId } = useAccount();
  const practice = useCurrentPractice();

  const [profileImageQuery] = useApiQueries([
    getProfileImageQuery({
      args: {
        practiceId: practice.id,
        userId,
      },
    }),
  ]);

  return (
    <div className="relative min-h-0 h-full pt-[50px]">
      <Header practice={practice}>
        <AccountsMenu thumbnail={profileImageQuery.data?.thumbnail} />
      </Header>
      <Outlet />
    </div>
  );
};
