import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { cx } from "@libs/utils/cx";

import { ReactComponent as ScheduleIcon } from "@libs/assets/icons/schedule.svg";
import { ReactComponent as AccountingIcon } from "@libs/assets/icons/accounting.svg";
import { ReactComponent as TreatmentIcon } from "@libs/assets/icons/treatment.svg";
import welcomeUrl from "assets/images/WelcomeIcon.svg";
import { semibold12 } from "assets/styles/textSize";
import { TwoColumnResponsiveLayout } from "components/UI/TwoColumnResponsiveLayout";

const SellingPoint: React.FC<{ children: React.ReactNode; text: string }> = ({ children, text }) => {
  return (
    <div
      className={cx(
        `space-y-2
         text-white
         text-center
         flex
         flex-col
         items-center
         justify-center
         w-1/3`,
        semibold12
      )}
    >
      <div>{children}</div>
      <div>{text}</div>
    </div>
  );
};

const LeftSideOnLargeScreens = () => {
  const { t } = useTranslation();

  return (
    <div
      className={`
        h-full
        flex-1
        flex
        flex-col
        bg-gradient-to-r
        from-primaryTheme
        to-archyBlue-700
        justify-center
        items-center
        text-center
        text-white
        space-y-5
        p-6
      `}
    >
      <div>
        <div
          className={`
            pt-4
            bg-opacity-10
            rounded-lg
            bg-whiteLight
            flex
            items-end
            justify-center
            w-[310px]
          `}
        >
          <img src={welcomeUrl} alt="Welcome" className="w-[154px]" />
        </div>
      </div>
      <div className="text-3xl leading-snug font-sansSemiBold">{t("Welcome!")}</div>
      <div className="flex flex-row space-x-2 justify-center">
        <SellingPoint text={t("Check your appointments")}>
          <ScheduleIcon />
        </SellingPoint>
        <SellingPoint text={t("Manage payments")}>
          <AccountingIcon />
        </SellingPoint>
        <SellingPoint text={t("Keep your information updated")}>
          <TreatmentIcon />
        </SellingPoint>
      </div>
    </div>
  );
};

export const SignedOutLayout = () => {
  return (
    <TwoColumnResponsiveLayout
      className="flex flex-col gap-5 pt-4 px-6 justify-center"
      leftSideOnLargeScreens={<LeftSideOnLargeScreens />}
    >
      <Outlet />
    </TwoColumnResponsiveLayout>
  );
};
