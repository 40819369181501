import React, { useEffect } from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { getPracticeLogoWithDimensions } from "components/PatientForms/FormPDFElements/utils/getPracticeLogo";

export const usePracticeLogo = (practice: PracticeInfoVO) => {
  const [logo, setLogo] = React.useState<
    | {
        w: number;
        h: number;
        url: string;
      }
    // Logo defaults to null (not loaded), we then set it to either a value with a width/height or undefined when it has been loaded but not set for the practice
    | undefined
    | null
  >(null);

  useEffect(() => {
    getPracticeLogoWithDimensions(practice).then((practiceLogoBase64) => {
      setLogo(practiceLogoBase64);
    });
  }, [practice]);

  return logo;
};
