import React from "react";
import { useTranslation } from "react-i18next";
import { AuthError } from "@aws-amplify/auth";
import { CognitoErrorCode, isCognitoErrorCode } from "@libs/utils/cognito";
import { useHandleError } from "api/handleErrorResponse";

type ErrorOrigin = "resend";

export const useAuthErrorHandler = (
  setErrorMessage: (err: string, cognitoError: CognitoErrorCode) => void
) => {
  const { t } = useTranslation();
  const handleError = useHandleError();

  const handleAuthError = React.useCallback(
    (error: unknown, errorOrigin?: ErrorOrigin) => {
      if (error instanceof AuthError) {
        if (error.name === "InvalidParameterException" && errorOrigin === "resend") {
          setErrorMessage(t(`cognito.resend.InvalidParameterException`), error.name);
        } else if (error.name && isCognitoErrorCode(error.name)) {
          setErrorMessage(t(`cognito.${error.name}`), error.name);
        } else {
          handleError(error);
          setErrorMessage(t(`cognito.Unknown`), "Unknown");
        }
      } else {
        handleError(error);
      }
    },
    [handleError, setErrorMessage, t]
  );

  return {
    handleAuthError,
  };
};
