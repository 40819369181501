import { makeQuery, makeInfiniteQuery, getNextPageParam } from "@libs/utils/queries";
import { noCache, oneMinuteCache, fiveMinuteCache, cacheForSession } from "@libs/utils/queryCache";

export const getUserMfa = makeQuery({
  queryKey: ["user", "getUserMfa"],
  formatParams: () => [],
  queryOptions: noCache,
});

export const getUserAccounts = makeQuery({
  queryKey: ["user", "getUserAccounts"],
  formatParams: (args: { userTypes?: ("EMPLOYEE" | "PATIENT" | "SUPER_ADMIN")[] }) => [args],
  queryOptions: oneMinuteCache,
});

export const getSupportUser = makeQuery({
  queryKey: ["practices", "getSupportUser"],
  formatParams: ({ practiceId, supportUserId }: { practiceId: number; supportUserId: number }) => [
    practiceId,
    supportUserId,
  ],
  queryOptions: cacheForSession,
});

// For support user only
export const listPractices = makeInfiniteQuery({
  queryKey: ["user", "listPractices"],
  formatParams: (query: {
    searchString: string;
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
  }) => [query],
  queryOptions: {
    ...fiveMinuteCache,
    getNextPageParam,
  },
});

// used for both patients and employees
export const getProfileImageQuery = makeQuery({
  queryKey: ["practices", "getProfileImage"],
  formatParams: ({ practiceId, userId }: { userId: number; practiceId: number }) => [userId, practiceId],
  queryOptions: oneMinuteCache,
});

export const getZendeskSsoJWT = makeQuery({
  queryKey: ["practices", "zendeskSso"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: oneMinuteCache,
});

export const getAssignableRolesForUser = makeQuery({
  queryKey: ["practices", "getAssignableRolesForUser"],
  formatParams: (args: { practiceId: number; userId: number }) => [args.practiceId, args.userId],
  queryOptions: oneMinuteCache,
});

export const getSsn = makeQuery({
  queryKey: ["practices", "getSsn"],
  formatParams: ({ userId, practiceId }: { practiceId: number; userId: number }) => [userId, practiceId],
  queryOptions: oneMinuteCache,
});
