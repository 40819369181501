import {
  setUser as setSentryUser,
  setTags as setSentryTags,
  setExtras as setSentryExtras,
} from "@sentry/react";
import { FC, useEffect, useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { PatientVO, PracticeFeatureVO, PracticeInfoVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { UNAUTHORIZED } from "@libs/utils/statusCodes";
import { PracticeContext } from "@libs/contexts/PracticeContext";
import { AccountContext } from "@libs/contexts/AccountContext";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { isPracticeLive } from "@libs/utils/isPracticeLive";
import { FeatureFlagsContextProvider } from "@libs/contexts/FeatureFlagsContext";
import { getPatientQuery } from "api/patient/queries";
import { getPracticeInfoByUuid } from "api/user/queries";
import { LoadingState } from "components/Main/LoadingState";
import { paths } from "router/paths";
import { PatientContext } from "contexts/PatientContext";
import { getPublicPracticeFeatureStates } from "api/self-booking/queries";
import { env } from "env";

const UserProvider: FC<{
  practice: PracticeInfoVO;
  patient: PatientVO;
  email: string;
}> = ({ practice, email, patient }) => {
  const account = useMemo(() => {
    return {
      practiceId: practice.id,
      practiceUuid: practice.uuid,
      email,
      isPracticeLive: isPracticeLive(practice.onboardingStatus),
      id: patient.id,
    };
  }, [practice.id, practice.uuid, practice.onboardingStatus, email, patient.id]);

  // These values will never change during a page load
  useEffect(() => {
    setSentryUser({ id: String(patient.id) });
    setSentryTags({
      ["practice.id"]: String(practice.id),
      ["practice.timezoneId"]: practice.timezoneId,
    });
    setSentryExtras({
      ["practice.uuid"]: practice.uuid,
    });
  }, [practice.id, patient.id, practice.uuid, practice.timezoneId]);

  // This will change often while a user onboards
  useEffect(() => {
    setSentryTags({
      onboardingState: patient.onboardingState,
    });
  }, [patient.onboardingState]);

  // This will change seldomly
  useEffect(() => {
    setSentryExtras({
      ["practice.name"]: practice.name,
    });
  }, [practice.name]);

  return (
    <AccountContext.Provider value={account}>
      <PatientContext.Provider value={patient}>
        <PracticeContext.Provider value={practice}>
          <Outlet />
        </PracticeContext.Provider>
      </PatientContext.Provider>
    </AccountContext.Provider>
  );
};

export const CurrentUserProvider: FC<{
  practiceUuid: string;
  email: string;
  userId: number;
  practiceId: number;
}> = ({ practiceId, practiceUuid, userId, email }) => {
  const [patientQuery, practiceQuery, practiceFeatureStatesQuery] = useApiQueries([
    getPatientQuery({ args: { practiceId, patientId: userId } }),
    getPracticeInfoByUuid({
      args: {
        practiceUuid,
      },
    }),
    getPublicPracticeFeatureStates({
      args: {
        practiceUuid,
      },
    }),
  ]);

  // Unauthorized errors are related to the user's tokens.
  // All other errors should just show an error screen
  const unauthorizedError = useMemo(() => {
    const patientError = patientQuery.error;
    const practiceError = practiceQuery.error;

    return patientError?.status === UNAUTHORIZED && practiceError?.status === UNAUTHORIZED;
  }, [patientQuery.error, practiceQuery.error]);

  const featureOverrides: PracticeFeatureVO["type"][] = useMemo(() => {
    if (env.REACT_APP_ENVIRONMENT !== "production") {
      return ["SCHEDULE_GUIDES"];
    }

    return [];
  }, []);

  return unauthorizedError ? (
    <Navigate
      replace
      to={paths.signOut({
        signOutReason: "UNAUTHORIZED",
      })}
    />
  ) : (
    <QueryResult
      queries={[patientQuery, practiceQuery, practiceFeatureStatesQuery]}
      loadError={<ErrorContent />}
      loading={<LoadingState />}
    >
      {patientQuery.data && practiceQuery.data && practiceFeatureStatesQuery.data ? (
        <FeatureFlagsContextProvider features={practiceFeatureStatesQuery.data} overrides={featureOverrides}>
          <UserProvider email={email} patient={patientQuery.data} practice={practiceQuery.data} />
        </FeatureFlagsContextProvider>
      ) : null}
    </QueryResult>
  );
};
