import React from "react";
import { useTranslation } from "react-i18next";
import { FormatReadOnlyValue, FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { PatientFormBooleanElement } from "components/PatientForms/FormElements/PatientFormBooleanElement";
import { PatientResponses, ResponseChangedCallback } from "components/PatientForms/hooks/usePatientResponses";
import { PatientFormSelectElement } from "components/PatientForms/FormElements/PatientFormSelectElement";
import { PatientFormValidation } from "components/PatientForms/hooks/usePatientFormValidation";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { PatientFormDateElement } from "components/PatientForms/FormElements/PatientFormDateElement";
import { FormInputElement } from "components/PatientForms/utils";
import { PatientFormNumberElement } from "components/PatientForms/FormElements/PatientFormNumberElement";

type Props = {
  element: FormInputElement;
  edit: boolean;
  responsesById: PatientResponses;
  onChangeResponse: ResponseChangedCallback;
  validation: PatientFormValidation;
};

export const PatientFormInputElement: React.FC<Props> = ({
  element,
  edit,
  responsesById,
  onChangeResponse,
  validation,
}) => {
  const { t } = useTranslation();
  const handleTextChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =
    React.useCallback(
      (e) => {
        onChangeResponse(element.uuid, {
          type: "STRING",
          response: e.target.value,
        });
      },
      [element.uuid, onChangeResponse]
    );

  const notProvided = t("Not Provided");
  const id = `question-${element.uuid}`;
  const { title, settings, description, uuid } = element;
  const required = settings.includes("REQUIRED");
  const handleReadOnlyValue: FormatReadOnlyValue = React.useCallback(
    (value) => {
      return value ?? notProvided;
    },
    [notProvided]
  );
  const sharedProps = { required, id, edit, label: title };
  const response = responsesById[uuid];
  const invalidationItem = validation[uuid];
  const inputError = invalidationItem?.error;

  switch (element.type) {
    case "NUMBER_INPUT": {
      return (
        <PatientFormNumberElement
          id={id}
          edit={edit}
          element={element}
          responsesById={responsesById}
          onChangeResponse={onChangeResponse}
          error={inputError}
          formatReadOnlyValue={handleReadOnlyValue}
        />
      );
    }

    case "BOOLEAN_INPUT": {
      return (
        <PatientFormBooleanElement
          {...sharedProps}
          element={element}
          error={inputError}
          validation={validation}
          responsesById={responsesById}
          onChangeResponse={onChangeResponse}
        />
      );
    }
    case "DATE_INPUT": {
      return (
        <PatientFormDateElement
          id={id}
          edit={edit}
          element={element}
          onChangeResponse={onChangeResponse}
          responsesById={responsesById}
          error={inputError}
        />
      );
    }

    case "SELECT_INPUT": {
      return (
        <PatientFormSelectElement
          {...sharedProps}
          element={element}
          invalidation={invalidationItem}
          responsesById={responsesById}
          onChangeResponse={onChangeResponse}
        />
      );
    }
    case "TEXT_INPUT": {
      return element.settings.includes("MULTILINE") ? (
        <FormFieldTextarea
          {...sharedProps}
          error={inputError}
          className="whitespace-pre-wrap"
          placeholder={description}
          value={response?.type === "STRING" ? response.response : undefined}
          onChange={handleTextChange}
        />
      ) : (
        <FormFieldInput
          {...sharedProps}
          formatReadOnlyValue={handleReadOnlyValue}
          error={inputError}
          className="max-w-md"
          placeholder={description}
          value={response?.type === "STRING" ? response.response : undefined}
          onChange={handleTextChange}
        />
      );
    }
    default: {
      return null;
    }
  }
};
