import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useValidation } from "@libs/hooks/useValidation";
import { eq } from "@libs/utils/validators";
import { useObjectState } from "@libs/hooks/useObjectState";

export type CardDraftState = {
  isFSACard?: boolean;
  isDefaultCard: boolean;
  isChargingAuthorized: boolean;
};

export const useCardDraft = (options: { includeAcknowedgeFuturePayments?: boolean }) => {
  const { t } = useTranslation();
  const [cardDraftState, updateCardDraft] = useObjectState<CardDraftState>({
    isDefaultCard: false,
    isFSACard: undefined,
    isChargingAuthorized: false,
  });
  const schema = useMemo(() => {
    return {
      isChargingAuthorized: [
        {
          $v: eq(true),
          $error: t("Please authorize card to continue."),
          $ignore: !options.includeAcknowedgeFuturePayments,
        },
      ],
    };
  }, [options.includeAcknowedgeFuturePayments, t]);
  const validation = useValidation(cardDraftState, schema);

  return {
    validation,
    updateCardDraft,
    cardDraftState,
  };
};
export type UseCardDraft = ReturnType<typeof useCardDraft>;
