import { ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { ValueTypes } from "@libs/components/UI/OptionInput";
import { Checkbox } from "@libs/components/UI/Checkbox";
import {
  OptionInputList,
  CheckboxInputListProps,
  OptionInputOption,
  applyOptionClassName,
  Layout,
} from "@libs/components/UI/OptionInputList";
import { LabelledOptions } from "@libs/components/UI/LabelledOptions";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";

const cxLabelThemes = {
  default: "text-sm md:text-xs font-sansSemiBold",
  regular: "text-sm md:text-xs",
};

export interface CheckboxListProps<V extends ValueTypes, T extends OptionInputOption<V>>
  extends Omit<CheckboxInputListProps<V, T>, "type"> {
  error?: string;
  label?: ReactNode;
  layout?: Layout;
  optionListClassName?: string;
  labelClassName?: string;
  labelTheme?: keyof typeof cxLabelThemes;
  required?: boolean;
  verticalLayout?: "compact" | "normal" | "comfortable";
  includeDarkMode?: boolean;
  textEllipsis?: boolean;
  parseNumbers?: boolean;
  description?: ReactNode;
  edit?: boolean;
  formatReadOnlyValue?: (selectedValues: Set<V>) => React.ReactNode;
}

export const CheckboxList = <V extends ValueTypes, T extends OptionInputOption<V>>({
  className,
  error,
  label,
  layout = "horiz",
  optionClassName,
  description,
  optionListClassName,
  labelClassName,
  labelTheme = "default",
  required,
  includeDarkMode = false,
  verticalLayout = "comfortable",
  textEllipsis = false,
  edit = true,
  formatReadOnlyValue = (v: Set<V>) => (v.size === 0 ? "-" : [...v].join(", ")),
  ...rest
}: CheckboxListProps<V, T>) => {
  return (
    <LabelledOptions
      className={className}
      labelClassName={cx(cxLabelThemes[labelTheme], labelClassName)}
      error={error}
      label={label}
      layout={layout}
      required={required}
      description={description}
    >
      {edit ? (
        <OptionInputList
          {...rest}
          className={cx(
            layout !== "custom" && "flex py-2",
            layout === "vert" ? "flex-col" : layout === "custom" ? "" : "items-center flex-wrap",
            optionListClassName,
            layout === "horiz" && "gap-x-8",
            layout !== "custom" && verticalLayout === "normal" && "gap-y-3",
            layout !== "custom" && verticalLayout === "comfortable" && "gap-y-4"
          )}
          optionClassName={(props) => cx(applyOptionClassName(optionClassName, props))}
          renderOptionItem={(props) => (
            <Checkbox {...props} includeDarkMode={includeDarkMode} textEllipsis={textEllipsis} />
          )}
          type="checkbox"
        />
      ) : (
        <span className={cxFormFieldStyle.controlValueOnly}>{formatReadOnlyValue(rest.selectedValues)}</span>
      )}
    </LabelledOptions>
  );
};
