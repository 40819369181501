import { useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { PracticeFeatureVO, PracticeInfoVO, SelfBookingAppointmentRequest } from "@libs/api/generated-api";
import { formatPhoneNumber } from "@libs/utils/phone";
import { formatISOTimeAsAmPm, formatLongDayOfMonth, getLocalDate } from "@libs/utils/date";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { FeatureFlagsContextProvider, useFeatureFlags } from "@libs/contexts/FeatureFlagsContext";
import { useHandleError } from "api/handleErrorResponse";
import { bookAppointment } from "api/self-booking/mutations";
import { getPublicPracticeFeatureStates, getSelfBookingConfigQuery } from "api/self-booking/queries";
import { getPracticeInfoByUuid } from "api/user/queries";
import { SelfBookingStep1 } from "components/SelfBooking/SelfBookingStep1";
import { SelfBookingStep2 } from "components/SelfBooking/SelfBookingStep2";
import { SelfBookingStep3 } from "components/SelfBooking/SelfBookingStep3";
import { useSelfBookingState } from "components/SelfBooking/useSelfBookingState";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { PublicPracticeInstrumentation } from "components/Main/PublicPracticeInstrumentation";
import { env } from "env";

export const SelfBookingContent: React.FC<{ practice: PracticeInfoVO }> = ({ practice }) => {
  const handleError = useHandleError();
  const { t } = useTranslation();
  const { practiceUuid: uuid } = usePathParams("selfBooking");
  const { query } = useQueryParams("selfBooking");
  const [step, setStep] = useState("1");
  const featuresEnabled = useFeatureFlags();

  const [confirmInfo, setConfirmInfo] = useState<{ day: string; time: string; isScheduled: boolean }>();

  const selfBookingState = useSelfBookingState();

  const [bookAppointmentMutation] = useApiMutations([bookAppointment]);

  const [selfBookableConfigQuery] = useApiQueries([
    getSelfBookingConfigQuery(featuresEnabled)({
      args: {
        practiceUuid: uuid,
      },
    }),
  ]);

  const handleBookAppointment = async (request: SelfBookingAppointmentRequest) => {
    try {
      const response = await bookAppointmentMutation.mutateAsync({
        practiceUuid: uuid,
        data: {
          ...request,
          messageCampaignUuid: query.messageCampaignUuid,
          useV2: featuresEnabled.has("SCHEDULE_GUIDES"),
        },
      });

      setConfirmInfo({
        day: formatLongDayOfMonth(getLocalDate(request.date)),
        time: formatISOTimeAsAmPm(request.startTime),
        isScheduled: response.data.data.selfBookingType === "SCHEDULE",
      });
      setStep("4");
      selfBookingState.resetState();
    } catch (e) {
      handleError(e);
    }
  };

  const isEnabled =
    selfBookableConfigQuery.data &&
    selfBookableConfigQuery.data.enabled &&
    selfBookableConfigQuery.data.appointmentCategories.length > 0;

  const phoneNumber = practice.phoneNumber || "";

  return (
    <QueryResult
      queries={[selfBookableConfigQuery]}
      loading={
        <div className="w-full h-full">
          <Skeleton className="w-full h-full p-6" />
        </div>
      }
    >
      {isEnabled && selfBookableConfigQuery.data ? (
        <>
          {(!step || step === "1") && (
            <SelfBookingStep1
              practiceInfo={practice}
              config={selfBookableConfigQuery.data}
              onNextStep={() => setStep("2")}
              {...selfBookingState}
            />
          )}
          {step === "2" && (
            <SelfBookingStep2
              practiceInfo={practice}
              config={selfBookableConfigQuery.data}
              onNextStep={() => setStep("3")}
              {...selfBookingState}
            />
          )}
          {step === "3" && (
            <SelfBookingStep3
              practiceInfo={practice}
              config={selfBookableConfigQuery.data}
              onNextStep={handleBookAppointment}
              onStartOver={() => setStep("1")}
              {...selfBookingState}
            />
          )}
          {step === "4" && (
            <AnonUserMessagePage
              homeUrl={practice.website}
              logoUrl={practice.logo?.url}
              title={t("selfBooking.completeTitle")}
              subTitle={
                <div className="text-center">
                  {t("selfBooking.completeText", {
                    type: confirmInfo?.isScheduled ? t("selfBooking.scheduled") : t("selfBooking.requested"),
                    day: confirmInfo?.day ?? "sdf",
                    time: confirmInfo?.time ?? "",
                  })}
                </div>
              }
            />
          )}
        </>
      ) : (
        <AnonUserMessagePage
          homeUrl={practice.website}
          logoUrl={practice.logo?.url}
          title={t("selfBooking.unavailableTitle")}
          subTitle={
            <div className="text-center">
              {t("selfBooking.unavailableText")}{" "}
              <a href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
            </div>
          }
        />
      )}
    </QueryResult>
  );
};

export const SelfBookingRoute = () => {
  const { practiceUuid: uuid } = usePathParams("selfBooking");

  const [practiceQuery, practiceFeatureStatesQuery] = useApiQueries([
    getPracticeInfoByUuid({
      args: {
        practiceUuid: uuid,
      },
    }),
    getPublicPracticeFeatureStates({
      args: {
        practiceUuid: uuid,
      },
    }),
  ]);

  const featureOverrides: PracticeFeatureVO["type"][] = useMemo(() => {
    if (env.REACT_APP_ENVIRONMENT !== "production") {
      return ["SCHEDULE_GUIDES"];
    }

    return [];
  }, []);

  return (
    <div className="flex flex-col h-full items-center bg-white overflow-y-auto">
      <QueryResult
        queries={[practiceQuery, practiceFeatureStatesQuery]}
        loading={
          <div className="w-full h-full">
            <Skeleton className="w-full h-full p-6" />
          </div>
        }
      >
        {practiceQuery.data && practiceFeatureStatesQuery.data ? (
          <>
            <PublicPracticeInstrumentation practice={practiceQuery.data} />
            <FeatureFlagsContextProvider
              features={practiceFeatureStatesQuery.data}
              overrides={featureOverrides}
            >
              <SelfBookingContent practice={practiceQuery.data} />
            </FeatureFlagsContextProvider>
          </>
        ) : null}
      </QueryResult>
    </div>
  );
};
