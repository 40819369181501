import { PDFViewer } from "@react-pdf/renderer";
import { PatientFormTasksResponse, PracticeInfoVO, ProcedurePriorityOptionVO } from "@libs/api/generated-api";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { formatDate } from "@libs/utils/date";
import { usePracticeLogo } from "components/PatientForms/hooks/usePracticeLogo";
import { PatientFormTasksPDF } from "components/PatientForms/PatientFormTasks/PatientFormTasksPDF";

type Props = {
  formTasksResponse: PatientFormTasksResponse;
  priorityOptions: ProcedurePriorityOptionVO[];
  practice: PracticeInfoVO;
};
export const PrintedFormTask: React.FC<Props> = ({ priorityOptions, practice, formTasksResponse }) => {
  const logo = usePracticeLogo(practice);
  const title = `Consent Tasks - ${formatDate(new Date())}`;

  usePageTitle(title);

  if (logo === null) {
    // Logo not loaded yet
    return null;
  }

  return (
    <PDFViewer className="w-full h-full">
      <PatientFormTasksPDF
        logo={logo}
        title={title}
        practice={practice}
        formTasksResponse={formTasksResponse}
        priorityOptions={priorityOptions}
      />
    </PDFViewer>
  );
};
