import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getPracticeInfoByUuid } from "api/user/queries";
import { usePathParams } from "hooks/usePathParams";
import { FormTaskLinkExpired } from "components/PatientForms/PatientFormTasks/FormTaskLinkExpired";
import { PublicPracticeInstrumentation } from "components/Main/PublicPracticeInstrumentation";

export const PatientFormTasksRoute = () => {
  const params = usePathParams("formTasks");
  const practiceUuid = params.practiceUuid;
  const [practiceQuery] = useApiQueries([
    getPracticeInfoByUuid({
      args: { practiceUuid },
    }),
  ]);

  return (
    <QueryResult queries={[practiceQuery]}>
      {practiceQuery.data ? (
        <>
          <PublicPracticeInstrumentation practice={practiceQuery.data} />
          <FormTaskLinkExpired practice={practiceQuery.data} />
        </>
      ) : null}
    </QueryResult>
  );
};
