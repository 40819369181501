import React, { useCallback, useMemo } from "react";

import { UpdateOnboardingInfoRequest } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { isNullish } from "@libs/utils/types";
import { OnboardingPdfUploaderPage } from "components/Onboarding/OnboardingPdfUploaderPage";
import { RegistrationAddPayment } from "components/Onboarding/RegistrationAddPayment";
import { PatientForm } from "components/PatientForms/PatientForm";

import { OnboardingHome } from "components/Onboarding/OnboardingHome";
import { OnboardingInsurance } from "components/Onboarding/OnboardingInsurance";
import { updateOnboardingInfo } from "api/user/mutations";
import { getPracticeBillingSetting } from "api/billing/queries";
import { RegistrationFooterPortalDestination } from "components/Onboarding/RegistrationFooterPortal";
import { GeneralInformation } from "components/Onboarding/GeneralInformation";
import { useHandleError } from "api/handleErrorResponse";
import { OnboardingStep, useOnboardingOrder } from "components/Onboarding/hooks/useOnboardingOrder";
import { OnboardingSectionsBar } from "components/Onboarding/PatientOnboardingWizard/OnboardingSectionsBar";
import { useCurrentPatient } from "contexts/PatientContext";

export const PatientOnboardingWizardRoute: React.FC = () => {
  const onboardingOrder = useOnboardingOrder();
  const { practiceId, id: patientId } = useAccount();
  const { onboardedWithPaymentProvider } = useCurrentPractice();
  const patient = useCurrentPatient();
  const userOnboardState = patient.onboardingState;
  const [{ mutateAsync: mutateOnboardingAsync }] = useApiMutations([updateOnboardingInfo]);

  const [currentOnboardIndex, setCurrentOnboardIndex] = React.useState(0);
  const hasSeenWelcome = useBoolean(userOnboardState !== "GENERAL_INFO");

  React.useEffect(() => {
    if (userOnboardState === "COMPLETED") {
      return;
    }

    let currentIndex = onboardingOrder.indexOf(userOnboardState);

    if (userOnboardState === "PAYMENT_INFO" && !onboardedWithPaymentProvider) {
      // User left off on finishing medical history, but server didn't properly update their onboarding state to consent
      currentIndex = onboardingOrder.indexOf("CONSENT");
    }

    if (currentIndex >= 0) {
      setCurrentOnboardIndex(currentIndex);

      if (userOnboardState !== "GENERAL_INFO") {
        hasSeenWelcome.on();
      }
    }
  }, [userOnboardState, hasSeenWelcome, onboardingOrder, onboardedWithPaymentProvider]);

  const onboardingState = onboardingOrder[currentOnboardIndex];
  const [{ data: billingSettings }] = useApiQueries([
    getPracticeBillingSetting({
      args: { practiceId },
      queryOptions: { enabled: onboardingState === "PAYMENT_INFO" },
    }),
  ]);
  const handleError = useHandleError();
  const handleUpdateOnboardingState = React.useCallback(
    async (params: { onboardingState: OnboardingStep; data: UpdateOnboardingInfoRequest }) => {
      if (!practiceId || !patientId) {
        // To investigate https://sentry.io/organizations/grindfoundry/issues/3423956788/?project=6000839&referrer=slack
        return handleError(
          new Error(`assertion failed, ids undefined patientId:${patientId}, practiceId:${practiceId}`)
        );
      }

      return mutateOnboardingAsync({
        practiceId,
        patientId,
        ...params,
      }).catch((e) => {
        handleError(e);
      });
    },
    [handleError, mutateOnboardingAsync, patientId, practiceId]
  );

  const handleFormSubmitted = React.useCallback(() => {
    setCurrentOnboardIndex((i) => {
      if (i < onboardingOrder.length - 1) {
        return i + 1;
      }

      return i;
    });
  }, [onboardingOrder.length]);

  const onPrevious = useCallback(() => {
    setCurrentOnboardIndex(currentOnboardIndex - 1 || 0);
  }, [currentOnboardIndex]);

  const activeRegistrationView = useMemo(() => {
    if (isNullish(onboardingState)) {
      return null;
    }

    switch (onboardingState) {
      case "GENERAL_INFO": {
        return <GeneralInformation onSuccess={handleFormSubmitted} />;
      }
      case "INSURANCE_INFO": {
        return <OnboardingInsurance onSuccess={handleFormSubmitted} onPrevious={onPrevious} />;
      }
      case "DENTAL_HISTORY_INFO": {
        return (
          <PatientForm
            uuidOrSlug="DENTAL_HISTORY"
            edit
            key="DENTAL_HISTORY"
            onPrevious={onPrevious}
            onSuccess={handleFormSubmitted}
            fixedFooter
          />
        );
      }
      case "MEDICAL_HISTORY_INFO": {
        return (
          <PatientForm
            key="MEDICAL_HISTORY"
            uuidOrSlug="MEDICAL_HISTORY"
            edit
            onPrevious={onPrevious}
            onSuccess={handleFormSubmitted}
            fixedFooter
          />
        );
      }
      case "PAYMENT_INFO": {
        return <RegistrationAddPayment onPrevious={onPrevious} onSuccess={handleFormSubmitted} />;
      }
      case "CONSENT": {
        return <PatientForm key="CONSENT" uuidOrSlug="CONSENT" edit onPrevious={onPrevious} fixedFooter />;
      }
      case "PDF_SUBMISSION": {
        return null;
      }
    }
  }, [handleFormSubmitted, onPrevious, onboardingState]);

  if (!onboardingState) {
    return null;
  }

  if (!hasSeenWelcome.isOn) {
    return <OnboardingHome onCompleted={hasSeenWelcome.on} />;
  }

  const paymentCollectionRequired = billingSettings?.collectPaymentInfoOnPatientPortal !== false;

  if (onboardingState === "PDF_SUBMISSION") {
    return <OnboardingPdfUploaderPage />;
  }

  return (
    <div className="border-x-greyLighter border-x h-full">
      <OnboardingSectionsBar
        onboardingState={onboardingState}
        showSkip={onboardingState === "PAYMENT_INFO" && !paymentCollectionRequired}
        onClickSkip={() => {
          handleUpdateOnboardingState({
            onboardingState: "PAYMENT_INFO",
            data: {},
          });
        }}
      />
      {activeRegistrationView}
      <RegistrationFooterPortalDestination />
    </div>
  );
};
