import React, { useMemo } from "react";
import {
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
} from "@sentry/react";
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ErrorResponse } from "@libs/api/generated-api";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { defaultSnackbarOptions } from "utils/snackbar";

export const useHandleError = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const genericError = useMemo(() => t("Sorry, something went wrong. Please try again."), [t]);

  return React.useCallback(
    (error: unknown) => {
      if (isHttpResponseError(error)) {
        handleErrorResponse(error.error, enqueueSnackbar, genericError, error.url);
      } else {
        enqueueSnackbar(genericError, defaultSnackbarOptions);
        sentryCaptureException(error);
      }
    },
    [enqueueSnackbar, genericError]
  );
};

const INDENTATION = 2;
const handleErrorResponse = (
  error: ErrorResponse,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  fallbackError: string,
  url?: string
) => {
  const errorMessages = (error.errors ?? []).map((e) => e.message).filter(Boolean) as string[];

  if (errorMessages.length === 0) {
    errorMessages.push(fallbackError);
  }

  for (const message of errorMessages) {
    enqueueSnackbar(message, defaultSnackbarOptions);
  }
  sentryCaptureMessage(JSON.stringify(error.errors, null, INDENTATION), {
    level: "error",
    extra: {
      traceId: error.traceId,
      url,
      status: error.status,
    },
  });
};
