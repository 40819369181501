import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isOneOf } from "@libs/utils/isOneOf";

const CARD_TYPES = [
  "DEBIT" as const,
  "CREDIT" as const,
  "HSA_FSA" as const,
  "RELOADABLE_PREPAID" as const,
  "NON_RELOADABLE_PREPAID" as const,
];

type FinixCardType = (typeof CARD_TYPES)[number];

const isFinixCardType = (cardType: string): cardType is FinixCardType => isOneOf(cardType, CARD_TYPES);

export const useLabelForCardType = (cardType: string) => {
  const { t } = useTranslation();

  return useMemo(() => {
    return isFinixCardType(cardType)
      ? t(`billing.paymentProfileType.${cardType}`)
      : t("billing.paymentProfileType.UNKNOWN");
  }, [cardType, t]);
};
