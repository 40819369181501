import React, { useMemo } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { pdf } from "@react-pdf/renderer";
import { subYears } from "date-fns";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { formatAsISODate, nowInTimezone } from "@libs/utils/date";
import { getProcedurePriorityOptions } from "@libs/api/charting/queries";
import { LoadedPatientForm } from "components/PatientForms/LoadedPatientForm";
import { getFormPreviewQuery } from "api/forms/queries";
import { getPracticeInfoByUuid } from "api/user/queries";
import { PatientFormPreviewHeader } from "components/PatientForms/PatientFormPreview/PatientFormPreviewHeader";
import { defaultSnackbarOptions } from "utils/snackbar";
import { PatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { PatientFormPdf } from "components/PatientForms/FormPDFElements/PatientFormPdf";
import { FormTaskChrome } from "components/PatientForms/PatientFormTasks/FormTaskChrome";
import { getPracticeLogoWithDimensions } from "components/PatientForms/FormPDFElements/utils/getPracticeLogo";
import { usePathParams } from "hooks/usePathParams";
import { PublicPracticeInstrumentation } from "components/Main/PublicPracticeInstrumentation";
import { ExistingPatientInfo } from "components/PatientForms/FormElements/types";
import { useQueryParams } from "hooks/useQueryParams";

export const PatientFormPreviewRoute = () => {
  const { t } = useTranslation();
  const [mockResponses, updateMockResponses] = useObjectState<PatientResponses>({});
  const params = usePathParams("previewForm");
  const {
    query: { referredByOptions },
  } = useQueryParams("previewForm");

  const { practiceUuid, formUuid } = params;

  if (!practiceUuid) {
    throw new Error("Practice uuid required to preview");
  }

  if (!formUuid) {
    throw new Error("uuidOrSlug required to preview");
  }

  const [previewFormQuery, practiceInfoQuery, procedurePriorityOptionsQuery] = useApiQueries([
    getFormPreviewQuery({
      args: {
        practiceUuid,
        uuid: formUuid,
      },
    }),
    getPracticeInfoByUuid({
      args: {
        practiceUuid,
      },
    }),
    getProcedurePriorityOptions({ args: { practiceUuid } }),
  ]);
  const { data: formData } = previewFormQuery;
  const { data: practiceData } = practiceInfoQuery;
  const { data: priorityOptions } = procedurePriorityOptionsQuery;

  const [patientAttributes, updatePatientAttributes] = useObjectState({
    age: 18,
    gender: "MALE" as ExistingPatientInfo["gender"],
  });

  const patientFormInfo: ExistingPatientInfo = useMemo(() => {
    const now = practiceData ? nowInTimezone(practiceData.timezoneId) : new Date();

    return {
      firstName: "Pat",
      lastName: "Smith",
      dob: formatAsISODate(subYears(now, patientAttributes.age)),
      gender: patientAttributes.gender,
      hasAddress: false,
      hasSsn: false,
      hasReferredBy: false,
      hasContact: false,
    };
  }, [patientAttributes, practiceData]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSuccessfulSubmission = React.useCallback(() => {
    enqueueSnackbar(t("pages.formPreview.validationSuccess"), defaultSnackbarOptions);
  }, [enqueueSnackbar, t]);
  const handlePdfClick = React.useCallback(async () => {
    if (practiceData && formData && priorityOptions) {
      // const logo = await getPracticeLogoBase64(practiceData);
      const logo = await getPracticeLogoWithDimensions(practiceData);

      const formPdf = await pdf(
        <PatientFormPdf
          practice={practiceData}
          patient={patientFormInfo}
          formData={formData}
          responses={mockResponses}
          logo={logo}
          priorityOptions={priorityOptions}
        />
      ).toBlob();
      const url = window.URL.createObjectURL(formPdf);

      window.open(url);

      // Clean up memory.
      window.URL.revokeObjectURL(url);
    }
  }, [formData, mockResponses, patientFormInfo, practiceData, priorityOptions]);

  return (
    <QueryResult queries={[previewFormQuery, procedurePriorityOptionsQuery]}>
      {formData && practiceData && priorityOptions && (
        <>
          <PublicPracticeInstrumentation practice={practiceData} />
          <PatientFormPreviewHeader
            onClickPdf={handlePdfClick}
            patientAttributes={patientAttributes}
            onChangePatientAttributes={updatePatientAttributes}
            formData={formData}
          />
          <div className="relative min-h-0">
            <FormTaskChrome practice={practiceData}>
              <LoadedPatientForm
                formData={formData}
                patient={patientFormInfo}
                responses={mockResponses}
                practice={practiceData}
                uuidOrSlug={formUuid}
                edit
                fixedFooter
                onSubmit={handleSuccessfulSubmission}
                showInWizard={false}
                onChangeResponses={updateMockResponses}
                referralOptions={referredByOptions ?? []}
                priorityOptions={priorityOptions}
              />
            </FormTaskChrome>
          </div>
        </>
      )}
    </QueryResult>
  );
};
