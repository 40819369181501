import { FC, useCallback, useEffect } from "react";
import {
  setUser as setSentryUser,
  setTags as setSentryTags,
  setExtras as setSentryExtras,
} from "@sentry/react";
import { signOut as amplifySignOut } from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { getAccountTokenStorage } from "@libs/storage/accountToken";
import { getTokensForAuthCheck } from "@libs/auth/getTokens";
import { useCurriedLoaderData } from "@libs/router/hooks";
import { getPostSignOutStorage } from "storage/postSignOut";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "router/paths";
import { PatientRouterContext } from "router/types";
import { getPatientActivityStorage } from "storage/activity";

export const loader =
  ({ storage }: PatientRouterContext) =>
  async () => {
    const tokens = await getTokensForAuthCheck(storage.localStorage);
    const activityStorage = getPatientActivityStorage(storage.localStorage);

    if (activityStorage.isRecentlyActive()) {
      return {
        email: tokens.identity?.email,
        accountToken: tokens.account,
      };
    }

    return {
      email: undefined,
      accountToken: undefined,
    };
  };

export const SignOutRoute: FC = () => {
  const { email, accountToken } = useCurriedLoaderData<typeof loader>();
  const { localStorage } = useStorageContext();
  const navigate = useNavigate();
  const { query } = useQueryParams("signOut");

  const signOut = useCallback(async () => {
    setSentryUser(null);
    setSentryTags({
      ["practice.id"]: null,
      ["practice.timezoneId"]: null,
      onboardingState: null,
    });
    setSentryExtras({
      ["practice.uuid"]: null,
      ["practice.name"]: null,
    });

    if (email && query.signOutReason) {
      const accountTokenStorage = getAccountTokenStorage(localStorage);

      accountTokenStorage.clearAccountToken(email);
    }

    const postSignOutStorage = getPostSignOutStorage(localStorage);

    postSignOutStorage.setParams({
      returnUrl: query.returnUrl,
      to: query.to,
      lastUserId: accountToken?.userId,
      signOutReason: query.signOutReason,
    });
    await amplifySignOut();

    // for federated sign out this will never be called. Calling
    // amplifySignOut above will rediect them to postSignOut
    navigate(paths.postSignOut());
  }, [navigate, email, query.returnUrl, query.to, query.signOutReason, accountToken?.userId, localStorage]);

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <LoadingOverlaySpinner centerVertically loadingText="Signing Out" />;
};
