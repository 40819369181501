import { FC, useMemo } from "react";
import { wrapCreateBrowserRouterV6 } from "@sentry/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { getRoutes } from "router/routes";
import { env } from "env";

export const PatientRouterProvider: FC = () => {
  const storage = useStorageContext();
  const queryClient = useQueryClient();
  const router = useMemo(() => {
    const create = wrapCreateBrowserRouterV6(createBrowserRouter);

    return create(getRoutes({ queryClient, storage, env, baseUrl: env.REACT_APP_API_HOST }));
  }, [queryClient, storage]);

  return <RouterProvider router={router} />;
};
