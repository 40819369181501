import manifest from "assets/manifest.json";

export const generateManifestJSON = () => {
  const pathname = window.location.pathname;
  const defaultManifest = {
    ...manifest,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    start_url: pathname,
  };
  // Allows for kiosk to be saved to homescreen
  const manifestBody = pathname.includes("/kiosk")
    ? {
        ...defaultManifest,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        short_name: "Archy Kiosk",
        name: "Archy Kiosk",
      }
    : defaultManifest;
  const content = encodeURIComponent(JSON.stringify(manifestBody));
  const url = `data:application/manifest+json,${content}`;
  const element = document.createElement("link");

  element.setAttribute("rel", "manifest");
  element.setAttribute("href", url);
  document.querySelector("head")?.append(element);
};
