import { useMemo, FC } from "react";
import { LoaderFunctionArgs, redirect, redirectDocument } from "react-router-dom";
import { getFullUrl } from "@libs/utils/location";
import { getTokensForAuthCheck } from "@libs/auth/getTokens";
import { useCurriedLoaderData } from "@libs/router/hooks";
import { SelectAccountPayload, SignOutPayload, useAuthChannelListeners } from "hooks/useAuthChannelListeners";
import { paths } from "router/paths";
import { PatientRouterContext } from "router/types";
import { CurrentUserProvider } from "components/Main/CurrentUserProvider";
import { getPatientActivityStorage } from "storage/activity";

export const loader =
  ({ storage }: PatientRouterContext) =>
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const tokens = await getTokensForAuthCheck(storage.localStorage);
    const returnUrl = getFullUrl(url);

    if (!tokens.identity) {
      return redirectDocument(paths.signIn({ returnUrl }));
    }

    const activityStorage = getPatientActivityStorage(storage.localStorage);

    if (!activityStorage.isRecentlyActive()) {
      return redirect(paths.signOut({ returnUrl }));
    }

    if (!tokens.account) {
      return redirectDocument(paths.selectAccount({ returnUrl }));
    }

    return {
      accountToken: tokens.account,
      email: tokens.identity.email,
    };
  };

export const SignedInApp: FC = () => {
  const { accountToken, email } = useCurriedLoaderData<typeof loader>();

  const authChannelEvents = useMemo(
    () => ({
      onSelectAccount: (event: SelectAccountPayload) => {
        if (event.userId !== accountToken.userId) {
          window.location.assign(paths.home());
        }
      },
      onSignOut: (data: SignOutPayload) => {
        window.location.assign(
          paths.signIn({
            signOutReason: data.reason,
            ...(data.addReturnUrl
              ? { returnUrl: getFullUrl(window.location), lastUserId: accountToken.userId }
              : undefined),
          })
        );
      },
    }),
    [accountToken.userId]
  );

  useAuthChannelListeners(authChannelEvents);

  return (
    <CurrentUserProvider
      // practiceUuid  was added to the token with the release
      // of patient portal using it so it will always be there
      practiceUuid={accountToken.practiceUuid as string}
      email={email}
      practiceId={accountToken.practiceId}
      userId={accountToken.userId}
    />
  );
};
