import { createContext, useContext, FC, PropsWithChildren, useMemo } from "react";
import { PracticeFeatureStateVO, PracticeFeatureVO } from "@libs/api/generated-api";

export type FeatureFlagsContextValue = Set<PracticeFeatureVO["type"]>;

const Context = createContext<FeatureFlagsContextValue>(new Set());

Context.displayName = "FeatureFlagsContext";

export const useFeatureFlags = () => useContext(Context);
export const FeatureFlagsContext = Context;

export const FeatureFlagsContextProvider: FC<
  PropsWithChildren<{ features: PracticeFeatureStateVO[]; overrides?: PracticeFeatureVO["type"][] }>
> = ({ children, overrides = [], features }) => {
  const value = useMemo(() => {
    return new Set([
      ...features.filter((item) => item.isEnabled).map((item) => item.practiceFeature.type),
      ...overrides,
    ]);
  }, [features, overrides]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
