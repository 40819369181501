import { useCallback } from "react";
import { Channels, browserTabId, useBroadcastChannelEffect } from "@libs/hooks/useBroadcastChannel";
import { SignOutReason } from "router/types";

export type SelectAccountPayload = { type: "selectAccount"; userId: number };
export type SignInPayload = { type: "signIn" };
export type SignOutPayload = { type: "signOut"; reason?: SignOutReason; addReturnUrl: boolean };
type AuthMessages = SignInPayload | SignOutPayload | SelectAccountPayload;
type AuthMessageHandler = (e: MessageEvent<AuthMessages & { browserTabId: string }>) => void;

export const useAuthChannelListeners = (events?: {
  onSelectAccount?: (data: SelectAccountPayload) => void;
  onSignIn?: (data: SignInPayload) => void;
  onSignOut?: (data: SignOutPayload) => void;
}) => {
  const handleAuthMessage: AuthMessageHandler = useCallback(
    (e) => {
      // only listen to messages from other tabs
      if (e.data.browserTabId === browserTabId) {
        return;
      }

      switch (e.data.type) {
        case "signOut": {
          events?.onSignOut?.(e.data);
          break;
        }
        case "signIn": {
          events?.onSignIn?.(e.data);
          break;
        }
        case "selectAccount": {
          events?.onSelectAccount?.(e.data);
          break;
        }
        default:
        // do nothing
      }
    },
    [events]
  );

  useBroadcastChannelEffect({ name: Channels.auth, onMessage: handleAuthMessage });
};

export const postAuthChannelMessage = (message: AuthMessages) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (window.BroadcastChannel === undefined) {
    return;
  }

  // eslint-disable-next-line compat/compat
  const channel = new BroadcastChannel(Channels.auth);

  channel.postMessage({
    ...message,
    browserTabId,
  });
};
