import { SelfBookingOpenSlotsRequest } from "@libs/api/generated-api";
import { FeatureFlagsContextValue } from "@libs/contexts/FeatureFlagsContext";
import { makeQuery } from "@libs/utils/queries";
import { cacheForSession, oneMinuteCache } from "@libs/utils/queryCache";

export const getSelfBookingConfigV2 = makeQuery({
  queryKey: ["public", "getSelfBookingConfigV2"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: cacheForSession,
});

export const getSelfBookingConfig = makeQuery({
  queryKey: ["public", "getSelfBookingConfig"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: cacheForSession,
});

export const getSelfBookingConfigQuery = (featureStates: FeatureFlagsContextValue) =>
  featureStates.has("SCHEDULE_GUIDES") ? getSelfBookingConfigV2 : getSelfBookingConfig;

export const getInsuranceCarrierNames = makeQuery({
  queryKey: ["public", "getInsuranceCarrierNames"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: cacheForSession,
});

export const getSelfBookableOpenSlotsV2 = makeQuery({
  queryKey: ["public", "getSelfBookableOpenSlotsV2"],
  formatParams: (args: { practiceUuid: string; data: SelfBookingOpenSlotsRequest }) => [
    args.practiceUuid,
    args.data,
  ],
  queryOptions: oneMinuteCache,
});

export const getSelfBookableOpenSlots = makeQuery({
  queryKey: ["public", "getSelfBookableOpenSlots"],
  formatParams: (args: { practiceUuid: string; data: SelfBookingOpenSlotsRequest }) => [
    args.practiceUuid,
    args.data,
  ],
  queryOptions: oneMinuteCache,
});

export const getSelfBookableSlotsQuery = (featureStates: FeatureFlagsContextValue) =>
  featureStates.has("SCHEDULE_GUIDES") ? getSelfBookableOpenSlotsV2 : getSelfBookableOpenSlots;

export const getSelfBookableProvidersV2 = makeQuery({
  queryKey: ["public", "getSelfBookableProvidersV2"],
  formatParams: ({
    practiceUuid,
    query,
  }: {
    practiceUuid: string;
    query: {
      carrierId?: number;
      appointmentCategoryId: number;
      startDate: string;
      selfBookingPatientType: "NEW" | "EXISTING";
    };
  }) => [practiceUuid, query],
  queryOptions: cacheForSession,
});

export const getSelfBookableProviders = makeQuery({
  queryKey: ["public", "getSelfBookableProviders"],
  formatParams: (args: {
    practiceUuid: string;
    query?: { carrierId?: number; appointmentCategoryId?: number };
  }) => [args.practiceUuid, args.query],
  queryOptions: cacheForSession,
});

export const getPublicPracticeFeatureStates = makeQuery({
  queryKey: ["public", "getPublicPracticeFeatureStates"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: cacheForSession,
});

export const getSelfBookableProvidersQuery = (featureStates: FeatureFlagsContextValue) =>
  featureStates.has("SCHEDULE_GUIDES") ? getSelfBookableProvidersV2 : getSelfBookableProviders;
