import { PatientFormTasksResponse, UpdatePatientDetailsRequest } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { isDefined } from "@libs/utils/types";
import { phoneNumber, required } from "@libs/utils/validators";

export const getSchema = ({
  gender,
  address,
  addressRequired,
}: Pick<PatientFormTasksResponse, "gender"> &
  Pick<UpdatePatientDetailsRequest, "mobilePhone" | "homePhone" | "workPhone" | "address"> & {
    addressRequired: boolean;
  }) => {
  // User has filled in some address fields, but not all required to submit a valid address
  const addressInProgress = [address?.address1, address?.city, address?.state, address?.zip].some(
    (item) => isDefined(item) && item.trim().length > 0
  );

  // Address can be ignored if it's not required and not in progress
  const ignoreAddress = !addressRequired && !addressInProgress;

  return {
    gender: [{ $v: required, $error: "Gender is required", $ignore: Boolean(gender) }],
    address: {
      address1: [{ $v: required, $error: "Address is required", $ignore: ignoreAddress }],
      city: [{ $v: required, $error: "City is required", $ignore: ignoreAddress }],
      state: [{ $v: required, $error: "State is required", $ignore: ignoreAddress }],
      zip: [{ $v: required, $error: "Zip is required", $ignore: ignoreAddress }],
    },
    mobilePhone: [{ $v: phoneNumber, $error: "Must be a valid phone number" }],
    homePhone: [{ $v: phoneNumber, $error: "Must be a valid phone number" }],
    workPhone: [{ $v: phoneNumber, $error: "Must be a valid phone number" }],
  };
};

export type PatientInfoFormValidation = ValidationResult<
  Omit<UpdatePatientDetailsRequest, "patientFormToken">,
  ReturnType<typeof getSchema>
>;
